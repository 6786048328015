import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Grid } from "@material-ui/core";
import { Layout } from "../components/Layout";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Seo from "../components/Seo/Seo";

import LadyLaptop from "../images/careers-page/lady-laptop.jpg";

import { useStyles } from "../pagesStyles/careers.styling";

const Careers = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Careers",
    },
  ];
  return (
    <Layout gutter marginTop breadcrumb={breadcrumbs}>
      <Seo title="Careers" />
      <section style={{ padding: desktop ? "0 5%" : 0 }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>Careers</strong>
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              PCH Services are looking for friendly, reliable candidates with
              attention to detail to join their team.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              We offer the following services: Office cleaning;
              companion/housekeeping; cleaning within client’s homes; end of
              tenancy cleaning; including deep cleaning services along with
              gardening services.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Are you looking for a job that fits around your family? Do you
              have a few hours a week to offer our clients some support around
              the home including
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              If so don’t delay get in touch with us today….
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>View Current Vacancies</strong>
            </Typography>
            <iframe
              frameborder="0"
              style={{ overFlow: "hidden", height: "auto", width: "100%" }}
              src="https://api.occupop.com/api/jobs-frame/Bp9pmbuDT8h86OtXMXcSEjUN6Q0s1DRmQmsvYH7o4P5JOEt9aJ?&search=1&button=Apply&color=%2368b2a7&visibility=external&settings_id=1887&fields=title,type,location,sector"
            ></iframe>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={LadyLaptop}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
    </Layout>
  );
};

export default Careers;
